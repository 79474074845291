<template>
  <b-card title="Female Future Leader Participant List">
    <b-col cols="12" class="d-flex align-items-center p-0 mt-1 mb-1">
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal-ffl-participant
        >
          Export Female Future Leader Participant
        </b-button>
      </b-col>
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.bulk-ffl-participant
          :disabled="!isTimelineAndStatusSelected"
        >
          Bulk FFL Participants
        </b-button>
      </b-col>
      <feather-icon
        size="20"
        icon="InfoIcon"
        class="ml-50"
        id="tooltip-target-1"
      />
      <b-tooltip
        target="tooltip-target-1"
        triggers="hover"
        placement="rightbottom"
      >
        Must select one of Timeline and one of submitted Status.
      </b-tooltip>
    </b-col>
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name / Email / Phone Number / Title"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="timeline">Timeline:</label>
          <v-select
            id="timeline"
            label="title"
            v-model="filter.timeline_uuid"
            :options="timelineList"
            placeholder="-- Pilih --"
            :reduce="(timelineList) => timelineList.uuid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @keyup.stop.native="searchTimeline"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="timeline">Status:</label>
          <v-select
            id="timeline"
            label="name"
            v-model="filter.status"
            :options="status"
            placeholder="-- Pilih --"
            :reduce="(status) => status.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="bulk-ffl-participant"
      centered
      title="Bulk FFL Participants"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label for="url">Approval Status:</label>
            <validation-provider name="url" rules="required">
              <v-select
                id="approval_status"
                label="name"
                v-model="formPayload.approval_status"
                :options="approval"
                placeholder="-- Pilih --"
                :reduce="(approval) => approval.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small
                v-for="(validation, index) in validations.approval_status"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="url">Url:</label>
            <validation-provider name="url" rules="required">
              <input
                type="text"
                class="form-control"
                :class="classes"
                placeholder="Url"
                v-model="formPayload.url"
              />
              <small
                v-for="(validation, index) in validations.url"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group row">
            <b-col cols="12" lg="6">
              <label for="startDate">Start Date:</label>
              <flat-pickr
                id="startDate"
                v-model="formPayload.start_date"
                class="form-control"
                :config="{
                  dateFormat: 'Y-m-d H:i:S',
                  enableTime: true,
                  enableSeconds: true,
                  time_24hr: true,
                }"
              />
              <small
                v-for="(validation, index) in validations.start_date"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="registrationEndDate">End Date:</label>
              <flat-pickr
                id="registrationEndDate"
                v-model="formPayload.end_date"
                class="form-control"
                :config="{
                  dateFormat: 'Y-m-d H:i:S',
                  enableTime: true,
                  enableSeconds: true,
                  time_24hr: true,
                }"
              />
              <small
                v-for="(validation, index) in validations.end_date"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
          </div>
        </div>

        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Bulk FFL Participants
        </b-button>
      </ValidationObserver>
    </b-modal>

    <Table
      :result="result"
      :participant="participant"
      :is-loading="isLoading"
      :get-data="getData"
      :chooseSelected="chooseSelected"
      :current-page="currentPage"
      :selected-list="selectedList"
      :is-timeline-and-status-selected="isTimelineAndStatusSelected"
      @update:selectedItems="updateSelectedItems"
    />
    <Modal
      :get-history="getParticipantHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams="filter"
    />
  </b-card>
</template>

<script>
import Table from "@/components/female-future-leader-participant/Table.vue";
import Modal from "@/components/female-future-leader-participant/Modal.vue";
import vSelect from "vue-select";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  BTooltip,
  VBModal,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    BTooltip,
    Modal,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      formPayload: {
        timeline_uuid: "",
        selected_status: "",
        approval_status: "",
        url: "",
        start_date: "",
        end_date: "",
        participants: [],
      },
      result: [],
      participant: {},
      timelineList: [],
      selectedList: [],
      link: "",
      filter: {},
      approval: [
        { value: "approve", name: "Approve" },
        { value: "reject", name: "Reject" },
      ],
      status: [
        { value: "registration-draft", name: "Registration Draft" },
        { value: "registration-submitted", name: "Registration Submitted" },
        { value: "registration-failed", name: "Registration Failed" },
        { value: "registration-passed", name: "Registration Passed" },
        {
          value: "e-learning-assignment-submitted",
          name: "E-Learning Assignment Submitted",
        },
        {
          value: "e-learning-assignment-failed",
          name: "E-Learning Assignment Failed",
        },
        {
          value: "e-learning-assignment-passed",
          name: "E-Learning Assignment Passed",
        },
        {
          value: "english-and-gmat-test-submitted",
          name: "English and GMAT Submitted",
        },
        {
          value: "english-and-gmat-test-failed",
          name: "English and GMAT Failed",
        },
        {
          value: "english-and-gmat-test-passed",
          name: "English and GMAT Passed",
        },
        {
          value: "leaderless-group-discussion-submitted",
          name: "Leaderless Group Discussion Submitted",
        },
        {
          value: "leaderless-group-discussion-failed",
          name: "Leaderless Group Discussion Failed",
        },
        {
          value: "leaderless-group-discussion-passed",
          name: "Leaderless Group Discussion Passed",
        },
        {
          value: "online-interview-submitted",
          name: "Online Interview Submitted",
        },
        { value: "online-interview-failed", name: "Online Interview Failed" },
        // { value: "online-interview-passed", name: "Online Interview Passed" },
        { value: "complete", name: "Complete" },
      ],
      histories: [],
      columns: [],
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.loadTimeline();
  },
  methods: {
    updateSelectedItems(newSelectedItems) {
      this.selectedList = newSelectedItems; // Update the parent's selectedList
      console.log(this.selectedList);
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/api/v1/admin/female-future-leaders/participant", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.participant = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadTimeline(search) {
      this.$http
        .get("/api/v1/admin/ffl-timelines", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.timelineList = response.data.data.items;
          console.log(this.timelineList);
        });
    },
    searchTimeline: _.debounce(function (e) {
      const search = e.target.value;
      this.loadTimeline(search);
    }, 300),
    preparePayload() {
      const form = new URLSearchParams();

      if (this.filter.timeline_uuid) {
        form.append("timeline_uuid", this.filter.timeline_uuid);
      }
      if (this.filter.status) {
        form.append("selected_status", this.filter.status);
      }

      // If there are selected participants, loop through and append each as "participant[]"
      if (this.selectedList.length > 0) {
        this.selectedList.forEach((participants) => {
          form.append("participants[]", participants);
        });
      }

      // Append other form data, excluding 'participant'
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          key !== "timeline_uuid" &&
          key !== "selected_status" &&
          key !== "participants" // Exclude 'participant' from being appended again
        ) {
          form.append(key, this.formPayload[key]);
        }
      }

      return form;
    },
    createItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post(
          "/api/v1/admin/female-future-leaders/bulk-approval-participant",
          form
        )
        .then((response) => {
          this.$bvModal.hide("bulk-ffl-participant");
          this.getData(this.currentPage);
          successNotification(
            this,
            "Success",
            "FFL Participants successfully bulked"
          );
          // this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            const validations = error.response.data.meta.validations;

            for (const field in validations) {
              if (validations.hasOwnProperty(field)) {
                validations[field] = validations[field].join(", ");
              }
            }

            this.validations = validations;
          } else {
            errorNotification(this, "Error", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    getParticipantHistory() {
      this.$http
        .get("/api/v1/admin/export/history/female-future-leader")
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=female-future-leader")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseSelected(slug, name) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure about this decision?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          if (name === "selected") {
            this.formSpotlight.is_selected = "true";
          } else {
            this.formSpotlight.is_favorite = "true";
          }
          this.$http
            .post(
              `/api/v1/admin/sister-store/${slug}/set-spotlight`,
              this.formSpotlight
            )
            .then((response) => {
              this.getData(this.currentPage);
              this.formSpotlight = {};
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Toko Sister berhasil di update!",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
  computed: {
    isTimelineAndStatusSelected() {
      return (
        this.filter.timeline_uuid &&
        this.filter.status &&
        this.filter.status.includes("submit")
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
